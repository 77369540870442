/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input:checked + div {
  @apply border-green-dark;
}
input:checked + div svg {
  @apply block;
}

.rc-steps-navigation .rc-steps-item-active .rc-steps-item-container {
  border-bottom: 3px solid #a3cd3a !important;
}

html {
  scroll-behavior: smooth;
}

input {
  font-size: 16px;
}

@font-face {
  font-family: "efectiGo";
  src: url("./assets/fonts/CoreSansM25-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "efectiGo";
  src: url("./assets/fonts/CoreSansM25-webfont.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "efectiGo";
  src: url("./assets/fonts/CoreSansM35-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "efectiGo";
  src: url("./assets/fonts/CoreSansM45-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "efectiGo";
  src: url("./assets/fonts/CoreSansM47-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "efectiGo";
  src: url("./assets/fonts/CoreSansM55-webfont.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "efectiGo";
  src: url("./assets/fonts/CoreSansM65-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "efectiGo";
  src: url("./assets/fonts/CoreSansM75-webfont.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "popup";
  src: url("./assets/fonts/Paloseco-Medium.ttf") format("truetype");
}

p {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  font-weight: 300;
  margin: 0;
}

body {
  font-family: "efectiGo" !important;
  font-size: 15px;
}


